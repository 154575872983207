<template>

  <v-app style="background-color: #343790;">
    
    <v-row>
      <v-col cols="2" class="mr-5">
        <img src="@/assets/great.svg" style="height:auto;margin-left:120px;" class="mt-5" >
       </v-col>
       <v-col cols="3">
         <p class="ml-16"
          style="
           
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            
            margin-top:20px;
            color: #e8e8ff;
          "
        >
          Your New Email signature
        </p>
       </v-col>
       <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
       
         <v-btn color="#DD2977" @click="help()" class="mt-8 ml-2 mr-3" style="height:38px;color:white;font-family: Roboto;font-style: normal;font-weight: 500;font-size:16px;">
          Help ?
        </v-btn>
       
       <v-col>
         <v-btn color="#FFFFFF" class="mt-5 mr-5 ml-1" outlined style="border-radius:2px;font-family: Roboto;font-size:14px;" 
         @click="manualLogout()">Sign Out</v-btn>
       </v-col>
    </v-row>
      <v-row dense class="mt-5">
        <v-col cols="3">  
          <img
          src="@/assets/hexagons.svg"
          width="376"
         
          class="hexagons"
          style="margin-top:30px;height:auto;"
        /></v-col>
        <v-col>
        <v-card
          color="#FFFFFF"
          class="mr-2 ml-9"
          style="border-radius: 10px;margin-top:-20px;"
          min-height="480"
          max-height="480"
          min-width="300"
          max-width="698"
        >
          <v-card-title style="background: #151515; height: 36px">
            <p
              class=""
              style="
                margin-top: -12px;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                color: #ffffff;
              "
            >
              New Message
            </p>
          </v-card-title>
          <v-card-text>
            <p
              style="
                color: #000000;
                font-size: 12px;
                font-family: Roboto;
                margin-top: 1px;
              "
            >
              To
            </p>
            <v-divider></v-divider>
            <p
              style="
                color: #000000;
                font-size: 12px;
                font-family: Roboto;
                margin-top: 1px;
              "
            >
              Subject
            </p>
            <v-divider class="mb-5"></v-divider>
          
             
             
              <!-- ****************************Templates*********************************** -->
              <div id="code1" v-if="template == 't1'" class="mx-auto">
                <table
                  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  min-width="300"
                  max-width="600"
                  style="
                    
                    height: 202px;
                    box-sizing: border-box;
                    border-spacing: 0px;
                    font-weight: 400;
                    letter-spacing: normal;
                    text-align: start;
                    white-space: normal;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                  "
                >
                  <tbody style="box-sizing: border-box">
                    <tr style="box-sizing: border-box">
                      <td width="150" style="padding: 0px;text-align:center;">
                        <img
                          v-if="previewImage!=''"
                          :src="`/assets/images/${previewImage}`"
                          style="
                           
                            width: 100px;
                           
                            height: 100px;
                            background-size: cover;
                            background-position: center center;
                          "
                        />
                        <img
                          v-bind:src="getUrl('crayonte.png')"
                          style="margin-top: 10px"
                        />
                        <div class="row">
                          <a :href="linkin"  v-if="linkin!=''" target="_blank">
                            <img
                              v-bind:src="linkedin"
                              style="margin-top: 8px;margin-left: 65px;"
                            />
                          </a>
                          <a :href="twitter" v-if="twitter!=''" target="_blank" >
                            <img
                              v-bind:src="twit"
                              :style="linkin!='' ? 'margin-top: 8px; margin-left: 5px':'margin-top: 8px; margin-left: 65px'"
                            />
                          </a>
                        </div>
                      </td>
                      <td
                        width="30"
                        style="box-sizing: border-box; padding: 0px"
                      >
                        <img v-bind:src="leftmedium" />
                      </td>

                      <td
                        width="360"
                        :style="`font-family:${var1};font-style:${var2};font-size: ${var3};color:${var4};font-weight:500;box-sizing: border-box; padding: 0px;`"
                      >
                        <span
                          style="
                            font-family: El Messiri;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 18px;
                            color: #34378f;
                          "
                        >
                          {{ fname1 }} {{ lname1 }}
                        </span>
                         <div style="display: -webkit-inline-box; width: 350px;font-family: ubuntu;
                            font-weight: 200;
                            font-size: 12px;
                            line-height: 18px;
                            color: #383838;">
                        <span>
                          {{ jobdesc }}
                        </span>
                        <span v-if="department != ''&&department!=undefined">
                         | {{ department }}
                        </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px;margin-top:2px;">
                          <img v-bind:src="phone" />
                           <span style="margin-left: 3px;">+ {{ officeno }}</span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="sphone" />
                          <span style="margin-left: 3px">+ {{ phoneno1 }}</span>
                          <span v-if="phoneno2 != ''&&phoneno2!=undefined">, + {{ phoneno2 }}</span>
                          <span v-if="phoneno3 != ''&&phoneno3!=undefined">, + {{ phoneno3 }}</span>
                          <span v-if="phoneno4 != ''&&phoneno4!=undefined">, + {{ phoneno4 }}</span>
                          <span v-if="phoneno5 != ''&&phoneno5!=undefined">, + {{ phoneno5 }}</span>
                          <span v-if="phoneno6 != ''&&phoneno6!=undefined">, + {{ phoneno6 }}</span>
                          <span v-if="phoneno7 != ''&&phoneno7!=undefined">, + {{ phoneno7 }}</span>
                          <span v-if="phoneno8 != ''&&phoneno8!=undefined">, + {{ phoneno8 }}</span>
                          <span v-if="phoneno9 != ''&&phoneno9!=undefined">, + {{ phoneno9 }}</span>
                          <span v-if="phoneno10 != ''&&phoneno10!=undefined">, + {{ phoneno10 }}</span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="emailicon" />
                          <span style="margin-left: 3px;">
                            {{ email1 }}
                          </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="earth" />
                          <span style="margin-left: 3px;">
                            {{ website }}
                          </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px;">
                          <img v-bind:src="map" />
                          <span style="margin-left: 3px">{{ address1 }}</span>
                          <span v-if="address2 != ''">, {{ address2 }}</span>
                          <span v-if="address3 != ''">, {{ address3 }}</span>
                          <span v-if="address4 != ''">, {{ address4 }}</span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 360px">
                          <img
                            v-bind:src="getUrl('Frame 8.png')"
                            style="margin-left: 0px; margin-top: 2px"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              
              <!-- ************************************************** -->        
              <v-card-actions>
                <v-row dense >
                <v-col cols="5">
                 <div style="
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    margin-top:100px;
                    cursor:pointer;
                    text-decoration-line: underline;
                    color: #FDB614;" class="ml-4" @click="backtosignature()">Create New Signature</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-col>
                 <img src="@/assets/edit.svg" @click="editsignature()" style="cursor:pointer;margin-top:92px;" class="ml-4">
                </v-col>
              </v-row>
              </v-card-actions>  
          </v-card-text>
         </v-card>
        
       
        </v-col>
    </v-row>
     <v-row  dense style="margin-top:2px;" class="mb-3">
       <v-spacer></v-spacer>
       <v-spacer></v-spacer>
          <v-btn
            color="#FDB614"
            outlined
            style="border-radius: 10px;"
            class="ml-5 mr-2"
            @click="copytemplate()"
            >Copy Signature</v-btn
          >
          <v-btn
            color="#FDB614"
            style="border-radius: 10px; color: #343790"
            @click="copysrc()"
            >Download As HTML</v-btn
          >
          <v-spacer></v-spacer>
        </v-row>
    <v-snackbar class="red--text" :color="snack_color" top v-model="snack"
        ><v-icon :color="snack_textcolor" class="mr-2 float-left">{{
          snack_icon
        }}</v-icon
        ><span :style="`color: ${snack_textcolor};font-size: 16px;`">{{
          snack_text
        }}</span
        ><v-icon
          @click="snack = !snack"
          :color="snack_textcolor"
          class="ml-2 mr-2 float-right"
          >mdi-close</v-icon
        ></v-snackbar
      >
  </v-app>
</template>

<script>
import Vue from "vue";
import MyPdf from '../assets/help/Help.pdf';
export default {
  name: "FinalPage",
  props: [
    "finaltemplate",
    "finalfname",
    "finallname",
    "finaljobdesc",
    "finalscolor",
    "finalphoneno1",
    "finalphoneno2",
    "finalphoneno3",
    "finalphoneno4",
    "finalphoneno5",
    "finalphoneno6",
    "finalphoneno7",
    "finalphoneno8",
    "finalphoneno9",
    "finalphoneno10",
    "finalemail",
    "finalwebsite",
    "finaladdress1",
    "finaladdress2",
    "finaladdress3",
    "finaladdress4",
    "finalpreviewImage",
    "finalvar1",
    "finalvar2",
    "finalvar3",
    "finalvar4",
    "finaltwitter",
    "finallinkin",
    "finaldepartment",
    "finalofficeno"
  ],
  data(){
    return{
      
      linkin: "https://www.linkedin.com/",
      twitter: "https://twitter.com/",
      var1: "ubuntu",
      var2: "normal",
      var3: "10px",
      var4: "#383838",
       template: "t1",
      fname: "John",
      lname: "Peter",
      jobdesc: "Developer",
      department:'Department',
      scolor: "#DD2977",
      phoneno1: "1234567890",
      phoneno2: "",
      phoneno3: "",
      phoneno4: "",
      phoneno5: "",
      phoneno6: "",
      phoneno7: "",
      phoneno8: "",
      phoneno9: "",
      phoneno10: "",
      officeno: "1234567890",
      email: "johnpeter@gmail.com",
      website: "www.crayonte.com",
      address1: "35-10",
      address2: "The Boulevard",
      address3: "Midvalley City 59200",
      address4: "Kuala lumpur",
      previewImage: "",
      snack_text: "",
      snack: false,
      snack_color: "",
      snack_textcolor: "",
      snack_icon: "",
      imgurl:''
    }
  },
  computed: {
     fname1(){
       if(this.$store.state.isLoggedIn==true){
        console.log(" computed fname")
        console.log(this.$store.state.LoggedInUser)
        if(this.finalfname!=undefined){
          return this.finalfname
        }
        return this.$store.state.Firstname
      }
      return ''
    },
    lname1(){
      if(this.$store.state.isLoggedIn==true){
       console.log(" computed lname")
        if(this.finallname!=undefined){
          return this.finallname
        }
        return  this.$store.state.Lastname
      }
       return''
    },
    email1(){
       if(this.$store.state.isLoggedIn==true){
        console.log("computed email")
        if(this.finalemail!=undefined){
          return this.finalemail
        }
       return this.$store.state.Emailid
      }
    return ''     
    },
   
    linkedin() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("linkedinp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("linkedinb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("linkediny.png");
      }
      return this.getUrl("linkedinp.png");
    },
    twit() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("twitp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("twitb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("twity.png");
      }
      return this.getUrl("twitp.png");
    },
    phone() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("phonep.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("phoneb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("phoney.png");
      }
      return this.getUrl("phonep.png");
    },
    sphone() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("sphonep.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("sphoneb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("sphoney.png");
      }
      return this.getUrl("sphonep.png");
    },
    emailicon() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("emailp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("emailb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("emaily.png");
      }
      return this.getUrl("emailp.png");
    },
    earth() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("earthp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("earthb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("earthy.png");
      }
      return this.getUrl("earthp.png");
    },
    map() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("mapp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("mapb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("mapy.png");
      }
      return this.getUrl("mapp.png");
    },
    leftmedium() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("leftpink.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("leftblue.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("leftyellow.png");
      }
      return this.getUrl("leftpink.png");
    },
    leftsmall() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("leftpinksmall.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("blue.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("yellow.png");
      }
      return this.getUrl("leftpinksmall.png");
    },
    left() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("p.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("b.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("y.png");
      }
      return this.getUrl("p.png");
    },
  },
    mounted() {
    console.log("mounted");
    console.log(this.template)
    console.log(this.finaljobdesc)
     console.log(window.location.origin)
    if(this.finaltemplate!=''&&this.finaltemplate!=undefined){
      this.template=this.finaltemplate
    }
    if(this.finalfname!=''&&this.finalfname!=undefined){
      this.fname=this.finalfname
    }
     if(this.finallname!=''&&this.finallname!=undefined){
      this.lname=this.finallname
    }
     if(this.finaljobdesc!=undefined){
      this.jobdesc=this.finaljobdesc
    }else{
      if(localStorage.getItem('Jobdescription')!=undefined && localStorage.getItem('Jobdescription')!=''){
        this.jobdesc=localStorage.getItem('Jobdescription')
      }
    }
     if(this.finalscolor!=undefined){
      this.scolor=this.finalscolor
    }
     if(this.finaldepartment!=undefined){
      this.department=this.finaldepartment
    }else{
      if(localStorage.getItem('Department')!=undefined && localStorage.getItem('Department')!=''){
        this.department=localStorage.getItem('Department')
      }
    }
     if(this.finalphoneno1!=undefined){
      this.phoneno1=this.finalphoneno1
    }else{
       if(localStorage.getItem('Phoneno1')!=undefined && localStorage.getItem('Phoneno1')!=''){
        this.phoneno1=localStorage.getItem('Phoneno1')
      }
    }
     if(this.finalphoneno2!=undefined){
      this.phoneno2=this.finalphoneno2
    }else{
       if(localStorage.getItem('Phoneno2')!=undefined && localStorage.getItem('Phoneno2')!=''){
        this.phoneno2=localStorage.getItem('Phoneno2')
      }
    }
     if(this.finalphoneno3!=undefined){
      this.phoneno3=this.finalphoneno3
    }else{
      if(localStorage.getItem('Phoneno3')!=undefined && localStorage.getItem('Phoneno3')!=''){
        this.phoneno3=localStorage.getItem('Phoneno3')
      }
    }
     if(this.finalphoneno4!=undefined){
      this.phoneno4=this.finalphoneno4
      }else{
      if(localStorage.getItem('Phoneno4')!=undefined && localStorage.getItem('Phoneno4')!=''){
        this.phoneno4=localStorage.getItem('Phoneno4')
      }
    }
     if(this.finalphoneno5!=undefined){
      this.phoneno5=this.finalphoneno5
      }else{
      if(localStorage.getItem('Phoneno5')!=undefined && localStorage.getItem('Phoneno5')!=''){
        this.phoneno5=localStorage.getItem('Phoneno5')
      }
    }
    if(this.finalphoneno6!=undefined){
      this.phoneno6=this.finalphoneno6
      }else{
      if(localStorage.getItem('phoneno6')!=undefined && localStorage.getItem('phoneno6')!=''){
        this.phoneno6=localStorage.getItem('phoneno6')
      }
    }
    if(this.finalphoneno7!=undefined){
      this.phoneno7=this.finalphoneno7
      }else{
      if(localStorage.getItem('phoneno7')!=undefined && localStorage.getItem('phoneno7')!=''){
        this.phoneno7=localStorage.getItem('phoneno7')
      }
    }
    if(this.finalphoneno8!=undefined){
      this.phoneno8=this.finalphoneno8
      }else{
      if(localStorage.getItem('phoneno8')!=undefined && localStorage.getItem('phoneno8')!=''){
        this.phoneno8=localStorage.getItem('phoneno8')
      }
    }
    if(this.finalphoneno9!=undefined){
      this.phoneno9=this.finalphoneno9
      }else{
      if(localStorage.getItem('phoneno9')!=undefined && localStorage.getItem('phoneno9')!=''){
        this.phoneno9=localStorage.getItem('phoneno9')
      }
    }
    if(this.finalphoneno10!=undefined){
      this.phoneno10=this.finalphoneno10
      }else{
      if(localStorage.getItem('phoneno10')!=undefined && localStorage.getItem('phoneno10')!=''){
        this.phoneno10=localStorage.getItem('phoneno10')
      }
    }
     if(this.finalemail!=undefined){
      this.email=this.finalemail
    }
     if(this.finalwebsite!=undefined){
      this.website=this.finalwebsite
    }else{
       if(localStorage.getItem('website')!=undefined && localStorage.getItem('website')!=''){
          this.website=localStorage.getItem('website')
        }
    }
     if(this.finaladdress1!=undefined){
      this.address1=this.finaladdress1
    }else{
       if(localStorage.getItem('Address1')!=undefined && localStorage.getItem('Address1')!=''){
        this.address1=localStorage.getItem('Address1')
      }
    }
     if(this.finaladdress2!=undefined){
      this.address2=this.finaladdress2
    }else{
       if(localStorage.getItem('Address2')!=undefined && localStorage.getItem('Address2')!=''){
        this.address2=localStorage.getItem('Address2')
      }
    }
     if(this.finaladdress3!=undefined){
      this.address3=this.finaladdress3
    }else{
      if(localStorage.getItem('Address3')!=undefined && localStorage.getItem('Address3')!=''){
        this.address3=localStorage.getItem('Address3')
      }
    }
     if(this.finaladdress4!=undefined){
      this.address4=this.finaladdress4
    }else{
       if(localStorage.getItem('Address4')!=undefined && localStorage.getItem('Address4')!=''){
          this.address4=localStorage.getItem('Address4')
        }
    }
    if(this.finalvar1!=undefined){
      this.var1=this.finalvar1
    }
    if(this.finalvar2!=undefined){
      this.var2=this.finalvar2
    }
    if(this.finalvar3!=undefined){
      this.var3=this.finalvar3
    }
    if(this.finalvar4!=undefined){
      this.var4=this.finalvar4
    }
    if(this.finalofficeno!=undefined){
      this.officeno=this.finalofficeno
    }else{
      if(localStorage.getItem('Officeno')!=undefined && localStorage.getItem('Officeno')!=''){
        this.officeno=localStorage.getItem('Officeno')
      }
    }
    if(this.finaltwitter!=undefined){
      this.twitter=this.finaltwitter
    }else{
       if(localStorage.getItem('twitter')!=undefined && localStorage.getItem('twitter')!=''){
        this.twitter=localStorage.getItem('twitter')
      }
    }
    if(this.finallinkin!=undefined){
      this.linkin=this.finallinkin
    }else{
        if(localStorage.getItem('linkin')!=undefined && localStorage.getItem('linkin')!=''){
          this.linkin=localStorage.getItem('linkin')
        }
    }
    if(this.finalpreviewImage!=undefined){
      this.previewImage=this.finalpreviewImage
    }else{
       if(localStorage.getItem('imgurl')!=undefined && localStorage.getItem('imgurl')!=''){
        this.previewImage=localStorage.getItem('imgurl')
      }
    }
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = "hidden";
  },
  methods: {
  
    help(){
       var axios = require("axios");
       axios({
        url: MyPdf,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "help.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
     manualLogout() {
      Vue.GoogleAuth.then((auth) => {
        auth.signOut().then((x) => {
          console.log(x);
          this.$store.state.isLoggedIn = false;
          this.$store.state.LoggedInUser = {};
           this.$store.state.Firstname='',
          this.$store.state.Lastname='',
          this.$store.state.Emailid='',

         
          this.snack = true;
          this.snack_color = "#26132E";
          this.snack_textcolor = "#00B300";
          this.snack_text = "Signout successfully";
          this.snack_icon = "mdi-alert-circle-outline";
           this.$router.push({
               name: "Home"})
          
        });
      });
    },
    backtosignature(){
       this.$router.push({
        name: "Signature",
         params: {
          edit:true,
          edittemplate: this.template,
          editfname: this.$store.state.Firstname,
          editlname: this.$store.state.Lastname,
          editjobdesc: 'Developer',
          editscolor: this.scolor,
          editphoneno1: "1234567890",
          editphoneno2: '',
          editphoneno3: '',
          editphoneno4:'',
          editphoneno5: '',
          editphoneno6: '',
          editphoneno7: '',
          editphoneno8: '',
          editphoneno9: '',
          editphoneno10: '',
          editemail: this.$store.state.Emailid,
          editwebsite: this.website,
          editaddress1: '35-10',
          editaddress2: 'The Boulevard',
          editaddress3: 'Midvalley City 59200',
          editaddress4: 'Kuala lumpur',
          editpreviewImage: '',
          editvar1: this.var1,
          editvar2: this.var2,
          editvar3: this.var3,
          editvar4: this.var4,
          editlinkin: "https://www.linkedin.com/",
          edittwitter: "https://twitter.com/",
          editdepartment:"Department",
          editofficeno:"1234567890"
        },
        });
    },
    getUrl(pet) {
      var path='/assets/images/'+pet;
      return path
    },
    copysrc() {
      var e;
      if (this.template == "t1") {
        e = document.getElementById("code1");
      } else if (this.template == "t2") {
        e = document.getElementById("code2");
      } else if (this.template == "t3") {
        e = document.getElementById("code3");
      } else if (this.template == "t4") {
        e = document.getElementById("code4");
      } else if (this.template == "t5") {
        e = document.getElementById("code5");
      } else if (this.template == "t6") {
        e = document.getElementById("code6");
      } else if (this.template == "t7") {
        e = document.getElementById("code7");
      } else if (this.template == "t8") {
        e = document.getElementById("code8");
      } else if (this.template == "t9") {
        e = document.getElementById("code9");
      }
      var content = e.innerHTML;
      this.imgurl=window.location.origin+"/assets/images/"
      content=content.replaceAll("/assets/images/",this.imgurl);
      const el = document.createElement("textarea");
      el.value = content;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
       var textToSaveAsBlob = new Blob([content], {type:"text/plain"});
    var url = window.URL.createObjectURL(textToSaveAsBlob);
       const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "signature.htm"); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
    copytemplate() {
      var e;
      if (this.template == "t1") {
        e = document.getElementById("code1");
      } else if (this.template == "t2") {
        e = document.getElementById("code2");
      } else if (this.template == "t3") {
        e = document.getElementById("code3");
      } else if (this.template == "t4") {
        e = document.getElementById("code4");
      } else if (this.template == "t5") {
        e = document.getElementById("code5");
      } else if (this.template == "t6") {
        e = document.getElementById("code6");
      } else if (this.template == "t7") {
        e = document.getElementById("code7");
      } else if (this.template == "t8") {
        e = document.getElementById("code8");
      } else if (this.template == "t9") {
        e = document.getElementById("code9");
      }
      console.log(e);
      var range = document.createRange();
      window.getSelection().removeAllRanges();
      range.selectNode(e);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
    editsignature(){
       this.$router.push({
        name: "Signature",
        params: {
          edit:true,
          edittemplate: this.template,
          editfname: this.fname1,
          editlname: this.lname1,
          editjobdesc: this.jobdesc,
          editscolor: this.scolor,
          editphoneno1: this.phoneno1,
          editphoneno2: this.phoneno2,
          editphoneno3: this.phoneno3,
          editphoneno4: this.phoneno4,
          editphoneno5: this.phoneno5,
          editphoneno6: this.phoneno6,
          editphoneno7: this.phoneno7,
          editphoneno8: this.phoneno8,
          editphoneno9: this.phoneno9,
          editphoneno10: this.phoneno10,
          editemail: this.email1,
          editwebsite: this.website,
          editaddress1: this.address1,
          editaddress2: this.address2,
          editaddress3: this.address3,
          editaddress4: this.address4,
          editpreviewImage: this.previewImage,
          editvar1: this.var1,
          editvar2: this.var2,
          editvar3: this.var3,
          editvar4: this.var4,
          editlinkin: this.linkin,
          edittwitter: this.twitter,
          editdepartment:this.department,
          editofficeno:this.officeno
        },
      });
    }
  },

   destroyed: function () {
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflowY = null;
  },
};
</script>
<style>
/* ::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #10102B; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #D6D7E9; 
  border-radius: 2px;
  max-height: 50px!important;
}

::-webkit-scrollbar-thumb:hover {
  background: #D6D7E9; 
} */
.theme--light.v-application {
    background: rgb(52, 55, 144)!important;
    color: rgba(0, 0, 0, 0.87);
}
</style>
