<template>
  <v-app style="background: #343790" class="signature">
   <p>{{fname1}} {{lname1}} {{email1}} </p>
  
    <v-app-bar  color="#343790" flat app>
    
     
        <img
          src="@/assets/logo2.svg"
          alt="Image testa alt"
        
          class="ml-3 "
        />
       
        <p class="builder ml-5"  >
          Email Signature Builder
        </p>
       
        <img src="@/assets/Group 1.svg" class="mt-2"   />
        <v-spacer/>
        <v-spacer/>
        <v-spacer/>
         <v-btn color="#DD2977" @click="help()" class="mt-5 ml-2 mr-5" style="height:33px;color:white;font-family: Roboto;font-style: normal;font-weight: 500;font-size:16px;">
          Help ?
        </v-btn>
         <v-btn color="#FFFFFF" class="mt-5 mr-8 ml-0" outlined style="width:88px;height:32px;border-radius:2px;font-family: Roboto;font-size:14px;" 
         @click="manualLogout()">Sign Out</v-btn>
     
    </v-app-bar>
    
      <v-row class="mt-1">
        <!-- <v-col cols="1"></v-col> -->
        <v-col class="ml-10" cols="4">
          <v-card
            color="#10102B"
            style="
              border-top-right-radius: 20px;
              border-top-left-radius: 20px;
              overflow: none;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
            "
            class="mx-auto"
            min-width="420"
            max-width="420"
            max-height="540px"
            min-height="540px"
          >
            <v-tabs background-color="#191A45" dark  >
              <v-tabs-slider color="#10102B"></v-tabs-slider>
              <v-tab style="color: #fdb614;width:111px;" v-if="user=='Admin'">
                Templates
              </v-tab>
              <v-tab :style="user!='Admin' ? 'color: #fdb614;width:210px;':'color: #fdb614;width:111px;'">
                Details
              </v-tab>
              <v-tab :style="user!='Admin' ? 'color: #fdb614;width:209px;':'color: #fdb614;width:111px;'">
                Social
              </v-tab>
              <v-tab style="color: #fdb614;width:111px;" v-if="user=='Admin'">
                Styles
              </v-tab>

              <v-tab-item v-if="user=='Admin'">
                <v-card
                  color="#10102B"
                  tile
                  max-height="490px"
                  min-height="490px"
                  min-width="426px"
                  style="overflow-y: scroll;"
                >
                  <v-card-title class="cardhead ml-3"
                    >Select Template</v-card-title
                  >
                  <v-card-text class="mx-auto">
                    <v-row v-if="user=='Admin'">
                      <v-col>
                        <img
                          :src="template=='t1' ? require('@/assets/st1.svg'):require('@/assets/t1.svg')"
                          style="cursor: pointer"
                          @click="template = 't1'"
                        />
                      </v-col>
                      <v-col>
                        <img
                          :src="template=='t2' ? require('@/assets/st2.svg'):require('@/assets/t2.svg')"
                          style="cursor: pointer"
                          @click="template = 't2'"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="user=='Admin'">
                      <v-col>
                        <img
                          :src="template=='t3' ? require('@/assets/st3.svg'):require('@/assets/t3.svg')"
                          style="cursor: pointer"
                          @click="template = 't3'"
                        />
                      </v-col>
                      <v-col>
                        <img
                         :src="template=='t4' ? require('@/assets/st4.svg'):require('@/assets/t4.svg')"
                          style="cursor: pointer"
                          @click="template = 't4'"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="user=='Admin'">
                      <v-col>
                        <img
                          :src="template=='t5' ? require('@/assets/st5.svg'):require('@/assets/t5.svg')"
                          style="cursor: pointer"
                          @click="template = 't5'"
                        />
                      </v-col>
                      <v-col>
                        <img
                         :src="template=='t6' ? require('@/assets/st6.svg'):require('@/assets/t6.svg')"
                          style="cursor: pointer"
                          @click="template = 't6'"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="user=='Admin'">
                      <v-col>
                        <img
                          :src="template=='t7' ? require('@/assets/st7.svg'):require('@/assets/t7.svg')"
                          style="cursor: pointer"
                          @click="template = 't7'"
                        />
                      </v-col>
                      <v-col>
                        <img
                          :src="template=='t8' ? require('@/assets/st8.svg'):require('@/assets/t8.svg')"
                          style="cursor: pointer"
                          @click="template = 't8'"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="user=='Admin'">
                      <v-col>
                        <img
                          :src="template=='t9' ? require('@/assets/st9.svg'):require('@/assets/t9.svg')"
                          style="cursor: pointer"
                          @click="template = 't9'"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- ***************************  Details  ****************************** -->
              <v-tab-item>
                 <v-form ref="EmailForm">
                <v-card 
                  color="#10102B"
                  tile
                  max-height="450px"
                  min-height="450px"
                  min-width="420px"
                  class="cus"
                  style="
                    overflow-y: scroll;
                    overflow-x: hidden;
                   border-radius:20px;
                  "
                >
                  <v-card-title class="cardhead ml-1"
                    >Enter Signature Details</v-card-title
                  >
                  <v-card-text class="mx-auto">
                    <v-row class="mx-auto" dense>
                      <v-col cols="4">
                        <p class="uploadprofile mt-8">Upload Profile</p>
                      </v-col>

                      <v-col>
                       
                        <div id="heximg">
                          <table
                            cellpadding="0"
                            border="0"
                            width="120"
                            height="110"
                            style="padding: 0px"
                          >
                            <tbody>
                              <tr>
                                <td width="5">
                                  <img
                                    src="@/assets/hexpink.png"
                                    style="height:100px;margin-top: 9px;margin-left:4px;"
                                  />
                                </td>
                                <td style="padding: 0px">
                                  <div
                                    style="
                                      display: flex;
                                      margin-left: -56px;
                                      margin-top: -8px;
                                      width: 84px;
                                      height: 104.38px;
                                      background-color: #424242;
                                      -webkit-clip-path:polygon(46% 2%, 94% 25%, 94% 78%, 50% 100%, 0% 75%, 0% 25%);
                                      clip-path: polygon(46% 2%, 94% 25%, 94% 78%, 50% 100%, 0% 75%, 0% 25%);
                                    "
                                  >
                                    <img :src="previewImage1" />
                                  </div>
                                </td>
                                <td width="5">
                                  <img
                                    src="@/assets/hexyellow.png"
                                    style="
                                      
                                      margin-left: -69px;
                                      margin-top: -8px;
                                    "
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                       
                      </v-col>
                      <v-col cols="4" class="mt-5">
                       <label class="forfile ml-5" style="margin-top:5px;" >
                         Choose File
                        <input
                         class="mt-15"
                          ref="fileInput"
                          type="file"
                         @input="pickFile"
                        />
                       </label>
                      <v-tooltip right color="#DD2977">
                          <template v-slot:activator="{ on, attrs }">
                          <img
                            @click="upload()"
                            v-bind="attrs"
                            v-on="on"
                            src="@/assets/upload btn.svg"
                            style="cursor: pointer"
                            class="mt-3 ml-10"
                          />
                          </template>
                        <span>Upload Image</span>
                      </v-tooltip>
                      <div class="forfile mt-2 ml-5" style="cursor:pointer;" v-if="previewImage!=''||previewImage1!=''" @click="clearimage()">Clear Image</div>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto mt-3 " dense>
                      <v-col>
                        <v-text-field
                          label="First Name"
                          :rules="validatefname(fname)"
                          v-model="fname"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Last Name"
                         :rules="validatelname(lname)"
                          v-model="lname"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Job Description"
                          dense
                          v-model="jobdesc"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field label="Department" dense v-model="department"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field label="Company Name" dense value="Crayonte" readonly></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto" dense>
                       <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="officenocode(ofcno)"
                          v-model="ofcno"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Office Phone No"
                          dense
                          @keypress="onlyNumber"
                          type="number"
                          :rules="validateofcno(officeno)"
                          v-model="officeno"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele1code(country1)"
                          v-model="country1"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          @keypress="onlyNumber"
                          :rules="validatephoneno1(phoneno1)"
                          v-model="phoneno1"
                           type="number"
                          dense
                        ></v-text-field>
                      </v-col>
                      <img
                      src="@/assets/addbtn.svg"
                        color="#D6D7E9"
                        small
                        style="cursor: pointer"
                        @click="addno = parseInt(addno) + 1"
                        />
                    </v-row>
                    <v-row class="mx-auto" dense v-if="addno >= 1">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele2code(country2)"
                          v-model="country2"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense v-if="addno >= 2">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele3code(country3)"
                          v-model="country3"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno3"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense v-if="addno >= 3">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele4code(country4)"
                          v-model="country4"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense v-if="addno >= 4">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele5code(country5)"
                          v-model="country5"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno5"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mx-auto" dense v-if="addno >= 5">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele6code(country6)"
                          v-model="country6"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno6"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mx-auto" dense v-if="addno >= 6">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele7code(country7)"
                          v-model="country7"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno7"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mx-auto" dense v-if="addno >= 7">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele8code(country8)"
                          v-model="country8"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                          @keypress="onlyNumber"
                           type="number"
                          v-model="phoneno8"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mx-auto" dense v-if="addno >= 8">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele9code(country9)"
                          v-model="country9"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                           type="number"
                           @keypress="onlyNumber"
                          v-model="phoneno9"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mx-auto" dense v-if="addno >= 9">
                      <v-col cols="4">
                        <v-autocomplete
                          dense
                          :items="country"
                          label="country"
                          @change="countrytele10code(country10)"
                          v-model="country10"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              v-bind:src="item.img"
                              width="20px"
                              height="20px"
                            />
                           <p style="color:rgba(255, 255, 255, 0.6);margin-left:8px;font-family:roboto;"> {{ item.text }}</p>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Mobile No"
                          dense
                          @keypress="onlyNumber"
                           type="number"
                          v-model="phoneno10"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                   <!-- ******************************************************** -->
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Website URL"
                          dense
                          :rules="validatewebsite(website)"
                          v-model="website"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Email"
                          dense
                          :rules="validateemail(email)"
                          v-model="email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Address1"
                          :rules="validateaddress1(address1)"
                          dense
                          placeholder="DoorNumber"
                          v-model="address1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Address2"
                          dense
                          placeholder="Street"
                          v-model="address2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Address3"
                          dense
                          placeholder="Landmark"
                          v-model="address3"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Address4"
                          dense
                          placeholder="City"
                          v-model="address4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                 </v-form>
              </v-tab-item>
              <!-- ***************************  Social  ************************* -->
              <v-tab-item>
                <v-card class="ml-1"
                  color="#10102B"
                  tile
                  max-height="450px"
                  min-height="450px"
                  min-width="436px"
                 
                 
                >
                  <v-card-title class="cardhead ml-1"
                    >Enter Social Links</v-card-title
                  >
                  <v-card-text class="mx-auto">
                    <v-row class="mx-auto" dense>
                      <v-col cols="1">
                        <img src="@/assets/linkedin.png" class="mt-2" />
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Linked In"
                          dense
                          class="mr-8 ml-2"
                          v-model="linkin"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col cols="1">
                        <img src="@/assets/Twitter icon.svg" class="mt-2" />
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Twitter"
                          dense
                          class="mr-8 ml-2"
                          v-model="twitter"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- *************************** Style **************************** -->
              <v-tab-item v-if="user=='Admin'">
                <v-card
                  color="#10102B"
                  tile
                  max-height="500px"
                  min-height="500px"
                  min-width="426px"
                >
                  <v-card-title class="cardhead ml-1">Text Style</v-card-title>
                  <v-card-text class="mx-auto">
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Font-Family"
                          dense
                          v-model="var1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Font-Style"
                          dense
                          v-model="var2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Font-Size"
                          dense
                          v-model="var3"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <v-text-field
                          label="Font-Color"
                          dense
                          v-model="var4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mx-auto" dense>
                      <v-col>
                        <p class="uploadprofile mt-2">Select Theme</p>
                      </v-col>
                      <v-col cols="2">
                        <img
                          :src="
                            scolor == '#DD2977'
                              ? require('@/assets/Rectangle9.svg')
                              : require('@/assets/Rectangle 9.svg')
                          "
                          class="mr-1"
                          style="cursor: pointer"
                          @click="scolor = '#DD2977'"
                        />
                      </v-col>
                      <v-col cols="2">
                        <img
                          :src="
                            scolor == '#FDB614'
                              ? require('@/assets/Rectangle10.svg')
                              : require('@/assets/Rectangle 10.svg')
                          "
                          class="mr-1"
                          style="cursor: pointer"
                          @click="scolor = '#FDB614'"
                        />
                      </v-col>
                      <v-col cols="2">
                        <img
                          :src="
                            scolor == '#343790'
                              ? require('@/assets/Rectangle11.svg')
                              : require('../assets/Rectangle 11.svg')
                          "
                          class="mr-1"
                          style="cursor: pointer"
                          @click="scolor = '#343790'"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- ************************************************************** -->
            </v-tabs>
          </v-card>
        </v-col>

        <v-col class="mr-3 ml-5" cols="7" lg="7" md="8" sm="8" xs="8">
          <v-row class="mb-1">
            <v-col cols="3">
            <v-btn
              color="#DD2977"
              style="color: white; height: 32px;"
              class="mt-1"
              @click="restart()"
              >Restart Form</v-btn
            >
            </v-col>
            <v-col cols="6">
              <v-row>
            <p class="signaturepreview mt-5" style="margin-left: 100px">
              SIGNATURE PREVIEW
            </p>
            <img src="@/assets/Vector.svg" class="ml-2 mt-4" />
              </v-row>
            </v-col>
            <v-col cols="2">
            <v-btn
              color="#FDB614"
              style="color: white; height: 32px;"
              @click="gotofinalpage()"
              class="mt-1 ml-16"
              >Done</v-btn
            >
            </v-col>
          </v-row>
          <v-card
            color="#FFFFFF"
            class="mr-2"
            style="border-radius:10px;"
            min-height="484"
            min-width="690"
            max-width="750"
           
          >
            <v-card-title style="background: #151515; height: 36px">
              <p
                class=""
                style="
                  margin-top: -12px;
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  color: #ffffff;
                "
              >
                New Message
              </p>
            </v-card-title>
            <v-card-text>
              <p
                style="
                  color: #000000;
                  font-size: 12px;
                  font-family: Roboto;
                  margin-top: 1px;
                "
              >
                To
              </p>
              <v-divider></v-divider>
              <p
                style="
                  color: #000000;
                  font-size: 12px;
                  font-family: Roboto;
                  margin-top: 1px;
                "
              >
                Subject
              </p>
              <v-divider class="mb-5"></v-divider>

              <!-- ****************************Templates*********************************** -->
              <div id="code1" v-if="template == 't1'" class="mx-auto">
              
                <table
                  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  style="
                    width: 600px;
                    height: 202px;
                    box-sizing: border-box;
                    border-spacing: 0px;
                    font-weight: 400;
                    letter-spacing: normal;
                    text-align: start;
                    white-space: normal;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                  "
                >
                  <tbody style="box-sizing: border-box">
                    <tr style="box-sizing: border-box">
                      <td width="150" style="padding: 0px;text-align:center;">
                        <img
                          v-if="previewImage!=''"
                          :src="`/assets/images/${previewImage}`"
                          style="
                           
                            width: 100px;
                           
                            height: 100px;
                            background-size: cover;
                            background-position: center center;
                          "
                        />
                        <img
                          v-bind:src="getUrl('crayonte.png')"
                          style="margin-top: 10px"
                        />
                        <div class="row">
                          <a :href="linkin"  v-if="linkin!=''" target="_blank">
                            <img
                              v-bind:src="linkedin"
                              style="margin-top: 8px;margin-left: 70px;"
                            />
                          </a>
                          <a :href="twitter" v-if="twitter!=''" target="_blank">
                            <img
                              v-bind:src="twit"
                              :style="linkin!='' ? 'margin-top: 8px; margin-left: 5px':'margin-top: 8px; margin-left: 70px'"
                            />
                          </a>
                        </div>
                      </td>
                      <td
                        width="30"
                        style="box-sizing: border-box; padding: 0px"
                      >
                        <img v-bind:src="leftmedium" />
                      </td>

                      <td
                        width="360"
                        :style="`font-family:${var1};font-style:${var2};font-size: ${var3};color:${var4};font-weight:500;box-sizing: border-box; padding: 0px;`"
                      >
                        <span
                          style="
                            font-family: El Messiri;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 15px;
                            color: #34378f;
                          "
                        >
                          {{ fname }} {{ lname }}
                        </span>
                         <div style="display: -webkit-inline-box; width: 350px;font-family: ubuntu;
                            font-weight: 200;
                            font-size: 12px;
                            line-height: 15px;
                            color: #383838;">
                        <span>
                          {{ jobdesc }}
                        </span>
                        <span v-if="department != ''">
                         | {{ department }}
                        </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px;margin-top:1px;">
                          <img v-bind:src="phone" />
                          <span style="margin-left: 3px">+ {{ officeno }}</span>
                          
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="sphone" />
                          <span style="margin-left: 3px">+ {{ phoneno1 }}</span>
                          <span v-if="phoneno2 != ''">,+ {{ phoneno2 }}</span>
                          <span v-if="phoneno3 != ''">,+ {{ phoneno3 }}</span>
                          <span v-if="phoneno4 != ''">,+ {{ phoneno4 }}</span>
                          <span v-if="phoneno5 != ''">,+ {{ phoneno5 }}</span>
                          <span v-if="phoneno6 != ''">,+ {{ phoneno6 }}</span>
                          <span v-if="phoneno7 != ''">,+ {{ phoneno7 }}</span>
                          <span v-if="phoneno8 != ''">,+ {{ phoneno8 }}</span>
                          <span v-if="phoneno9 != ''">,+ {{ phoneno9 }}</span>
                          <span v-if="phoneno10 != ''">,+ {{ phoneno10 }}</span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="emailicon" />
                          <span style="margin-left: 3px">
                            {{ email }}
                          </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="earth" />
                          <span style="margin-left: 3px">
                            {{ website }}
                          </span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 350px">
                          <img v-bind:src="map" />
                          <span style="margin-left: 3px">{{ address1 }}</span>
                          <span v-if="address2 != ''">, {{ address2 }}</span>
                          <span v-if="address3 != ''">, {{ address3 }}</span>
                          <span v-if="address4 != ''">, {{ address4 }}</span>
                        </div>
                        <div style="display: -webkit-inline-box; width: 360px">
                          <img
                            v-bind:src="getUrl('Frame 8.png')"
                            style="margin-left: 0px; margin-top: 2px"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
             
              <!-- ************************************************** -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    <v-snackbar class="red--text" :color="snack_color" top v-model="snack"
        ><v-icon :color="snack_textcolor" class="mr-2 float-left">{{
          snack_icon
        }}</v-icon
        ><span :style="`color: ${snack_textcolor};font-size: 16px;`">{{
          snack_text
        }}</span
        ><v-icon
          @click="snack = !snack"
          :color="snack_textcolor"
          class="ml-2 mr-2 float-right"
          >mdi-close</v-icon
        ></v-snackbar
      >
  </v-app>
</template>
<script src="path/to/dom-to-image.min.js" />

<script>
import Vue from "vue";

import baseurl from "@/baseurl.json";
import domtoimage from "dom-to-image";
import MyPdf from '../assets/help/Help.pdf';
export default {
  name: "signature",
  created() {
    console.log("signature created")
    console.log(this.$store.state.isLoggedIn)
    
  },
   props: [
    "edit",
    "edittemplate",
    "editfname",
    "editlname",
    "editjobdesc",
    "editscolor",
    "editphoneno1",
    "editphoneno2",
    "editphoneno3",
    "editphoneno4",
    "editphoneno5",
    "editphoneno6",
    "editphoneno7",
    "editphoneno8",
    "editphoneno9",
    "editphoneno10",
    "editemail",
    "editwebsite",
    "editaddress1",
    "editaddress2",
    "editaddress3",
    "editaddress4",
    "editpreviewImage",
    "editvar1",
    "editvar2",
    "editvar3",
    "editvar4",
    "edittwitter",
    "editlinkin",
    "editdepartment",
    "editofficeno"
  ],
  mounted(){
    console.log("Signaturemounted")
    console.log(this.$store.state.isLoggedIn)
    console.log(this.$store.state.LoggedInUser)
    if(localStorage.getItem('Department')!=undefined && localStorage.getItem('Department')!=''){
     this.department=localStorage.getItem('Department')
   }
    if(localStorage.getItem('Jobdescription')!=undefined && localStorage.getItem('Jobdescription')!=''){
     this.jobdesc=localStorage.getItem('Jobdescription')
   }
   if(localStorage.getItem('Address1')!=undefined && localStorage.getItem('Address1')!=''){
     this.address1=localStorage.getItem('Address1')
   }
    if(localStorage.getItem('Address2')!=undefined && localStorage.getItem('Address2')!=''){
     this.address2=localStorage.getItem('Address2')
   }
    if(localStorage.getItem('Address3')!=undefined && localStorage.getItem('Address3')!=''){
     this.address3=localStorage.getItem('Address3')
   }
    if(localStorage.getItem('Address4')!=undefined && localStorage.getItem('Address4')!=''){
     this.address4=localStorage.getItem('Address4')
   }
   if(localStorage.getItem('Officeno')!=undefined && localStorage.getItem('Officeno')!=''){
     this.officeno=localStorage.getItem('Officeno')
   }
   if(localStorage.getItem('Phoneno1')!=undefined && localStorage.getItem('Phoneno1')!=''){
     this.phoneno1=localStorage.getItem('Phoneno1')
   }
    if(localStorage.getItem('Phoneno2')!=undefined && localStorage.getItem('Phoneno2')!=''){
     this.phoneno2=localStorage.getItem('Phoneno2')
   }
    if(localStorage.getItem('Phoneno3')!=undefined && localStorage.getItem('Phoneno3')!=''){
     this.phoneno3=localStorage.getItem('Phoneno3')
   }
    if(localStorage.getItem('Phoneno4')!=undefined && localStorage.getItem('Phoneno4')!=''){
     this.phoneno4=localStorage.getItem('Phoneno4')
   }
    if(localStorage.getItem('Phoneno5')!=undefined && localStorage.getItem('Phoneno5')!=''){
     this.phoneno5=localStorage.getItem('Phoneno5')
   }
    if(localStorage.getItem('Phoneno6')!=undefined && localStorage.getItem('Phoneno6')!=''){
     this.phoneno6=localStorage.getItem('Phoneno6')
   }
    if(localStorage.getItem('Phoneno7')!=undefined && localStorage.getItem('Phoneno7')!=''){
     this.phoneno7=localStorage.getItem('Phoneno7')
   }
    if(localStorage.getItem('Phoneno8')!=undefined && localStorage.getItem('Phoneno8')!=''){
     this.phoneno8=localStorage.getItem('Phoneno8')
   }
    if(localStorage.getItem('Phoneno9')!=undefined && localStorage.getItem('Phoneno9')!=''){
     this.phoneno9=localStorage.getItem('Phoneno9')
   }
    if(localStorage.getItem('Phoneno10')!=undefined && localStorage.getItem('Phoneno10')!=''){
     this.phoneno10=localStorage.getItem('Phoneno10')
   }
   if(localStorage.getItem('twitter')!=undefined && localStorage.getItem('twitter')!=''){
     this.twitter=localStorage.getItem('twitter')
   }
   if(localStorage.getItem('linkin')!=undefined && localStorage.getItem('linkin')!=''){
     this.linkin=localStorage.getItem('linkin')
   }
   if(localStorage.getItem('website')!=undefined && localStorage.getItem('website')!=''){
     this.website=localStorage.getItem('website')
   }
   if(localStorage.getItem('imgurl')!=undefined && localStorage.getItem('imgurl')!=''){
     this.previewImage=localStorage.getItem('imgurl')
   }
   if(this.edit==true){
          this.template= this.edittemplate,
          this.fname= this.editfname,
          this.lname= this.editlname,
          this.jobdesc= this.editjobdesc,
          this.scolor= this.editscolor,
          this.phoneno1= this.editphoneno1,
          this.phoneno2= this.editphoneno2,
          this.phoneno3= this.editphoneno3,
          this.phoneno4= this.editphoneno4,
          this.phoneno5= this.editphoneno5,
          this.phoneno6= this.editphoneno6,
          this.phoneno7= this.editphoneno7,
          this.phoneno8= this.editphoneno8,
          this.phoneno9= this.editphoneno9,
          this.phoneno10= this.editphoneno10,
          this.email= this.editemail,
          this.website= this.editwebsite,
          this.address1= this.editaddress1,
          this.address2= this.editaddress2,
          this.address3= this.editaddress3,
          this.address4= this.editaddress4,
          this.previewImage= this.editpreviewImage,
          this.var1= this.editvar1,
          this.var2= this.editvar2,
          this.var3= this.editvar3,
          this.var4=this.editvar4,
          this.linkin= this.editlinkin,
          this.twitter= this.edittwitter,
          this.department=this.editdepartment,
          this.officeno=this.editofficeno
    }
  },
  computed: {
    fname1(){
       if(this.$store.state.isLoggedIn==true){
        console.log(" computed fname")
        console.log(this.$store.state.LoggedInUser)
       console.log(this.$store.state.Firstname)
        this.fname=this.$store.state.Firstname
      
      }
      
    },
    lname1(){
      if(this.$store.state.isLoggedIn==true){
       console.log(" computed lname")
       this.lname=this.$store.state.Lastname
      
      }
       
    },
    email1(){
       if(this.$store.state.isLoggedIn==true){
        console.log("computed email")
       this.email=this.$store.state.Emailid
       
      }
     
    },
    //  profile(){
    //    if(this.$store.state.isLoggedIn==true){
    //     console.log("computed imgurl")
    //    this.previewImage1=this.$store.state.imgurl
      
    //   }
     
    // },
    linkedin() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("linkedinp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("linkedinb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("linkediny.png");
      }
      return this.getUrl("linkedinp.png");
    },
    twit() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("twitp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("twitb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("twity.png");
      }
      return this.getUrl("twitp.png");
    },
    phone() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("phonep.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("phoneb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("phoney.png");
      }
      return this.getUrl("phonep.png");
    },
    sphone() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("sphonep.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("sphoneb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("sphoney.png");
      }
      return this.getUrl("sphonep.png");
    },
    emailicon() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("emailp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("emailb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("emaily.png");
      }
      return this.getUrl("emailp.png");
    },
    earth() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("earthp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("earthb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("earthy.png");
      }
      return this.getUrl("earthp.png");
    },
    map() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("mapp.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("mapb.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("mapy.png");
      }
      return this.getUrl("mapp.png");
    },
    leftmedium() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("leftpink.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("leftblue.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("leftyellow.png");
      }
      return this.getUrl("leftpink.png");
    },
    leftsmall() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("leftpinksmall.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("blue.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("yellow.png");
      }
      return this.getUrl("leftpinksmall.png");
    },
    left() {
      if (this.scolor == "#DD2977") {
        return this.getUrl("p.png");
      } else if (this.scolor == "#343790") {
        return this.getUrl("b.png");
      } else if (this.scolor == "#FDB614") {
        return this.getUrl("y.png");
      }
      return this.getUrl("p.png");
    },
  },
  data() {
    return {
      user:'Developer',
      base: "http://localhost:8080/assets/images/",
      linkin: "https://www.linkedin.com/",
      twitter: "https://twitter.com/",
      addno: 0,
      var1: "ubuntu",
      var2: "normal",
      var3: "10px",
      var4: "#383838",
      country: [
        { text: "India", img: require("../assets/india.svg") },
        { text: "Malaysia", img: require("../assets/malaysia.svg") },
        { text: "Singapore", img: require("../assets/singapore.svg") },
        { text: "China", img: require("../assets/china.svg") },
        { text: "USA", img: require("../assets/usa.svg") },
        { text: "Philippines", img: require("../assets/philipines.svg") },
        { text: "Japan", img: require("../assets/japan.svg") },
        { text: "Pakistan", img: require("../assets/pakistan.svg") },
        { text: "Bangladesh", img: require("../assets/bangladesh.svg") },
        { text: "Israel", img: require("../assets/israel.svg") },
        { text: "Indonesia", img: require("../assets/Indonesia.svg") },
        { text: "Iran", img: require("../assets/Iran.svg") },
        { text: "Iraq", img: require("../assets/Iraq.svg") },
        { text: "Maldives", img: require("../assets/Maldives.svg") },
        { text: "Arab", img: require("../assets/Arab.svg") },
        { text: "Mongolia", img: require("../assets/Mongolia.svg") },
        { text: "Saudiarabia", img: require("../assets/Saudiarabia.svg") },
        { text: "Cambodia", img: require("../assets/Cambodia.svg") },
        { text: "Kazakhstan", img: require("../assets/Kazakhstan.svg") },
        { text: "Nepal", img: require("../assets/Nepal.svg") },
        { text: "Uzbekistan", img: require("../assets/Uzbekistan.svg") },
        { text: "Brunei", img: require("../assets/Brunei.svg") },
        { text: "Myanmar", img: require("../assets/Myanmar.svg") },
        { text: "Jordan", img: require("../assets/Jordan.svg") },
        { text: "Turkmenistan", img: require("../assets/Turkmenistan.svg") },
        { text: "Bhutan", img: require("../assets/Bhutan.svg") },
        { text: "Thailand", img: require("../assets/Thailand.svg") },
        { text: "Tajikistan", img: require("../assets/Tajikistan.svg") },
        { text: "Syria", img: require("../assets/Syria.svg") },
        { text: "Sri Lanka", img: require("../assets/Sri Lanka.svg") },
        { text: "Hong Kong", img: require("../assets/Hong Kong.svg") },
        { text: "Laos", img: require("../assets/Laos.svg") },
        { text: "Armenia", img: require("../assets/Armenia.svg") },
        { text: "South Korea", img: require("../assets/South Korea.svg") },
        { text: "Georgia", img: require("../assets/Georgia.svg") },
        { text: "Oman", img: require("../assets/Oman.svg") },
        { text: "Afghanistan", img: require("../assets/Afghanistan.svg") },
        { text: "Kyrgyzstan", img: require("../assets/Kyrgyzstan.svg") },
        { text: "East Timor", img: require("../assets/East Timor.svg") },
        { text: "Yemen", img: require("../assets/Yemen.svg") },
        { text: "Kuwait", img: require("../assets/Kuwait.svg") },
        { text: "Vietnam", img: require("../assets/Vietnam.svg") },

      ],
      tabitems: ["Templates", "Details", "Social", "Styles"],
      template: "t1",
      fname: "",
      lname: "",
      jobdesc: "Developer",
      department:'Department',
      scolor: "#DD2977",
      phoneno1: "1234567890",
      phoneno2: "",
      phoneno3: "",
      phoneno4: "",
      phoneno5: "",
      phoneno6:'',
      phoneno7:'',
      phoneno8:'',
      phoneno9:'',
      phoneno10:'',
      officeno: "1234567890",
      email: "",
      website: "www.crayonte.com",
      address1: "35-10",
      address2: "The Boulevard",
      address3: "Midvalley City 59200",
      address4: "Kuala lumpur",
      previewImage: "",
      previewImage1: "",
      country1: "",
      country2: "",
      country3: "",
      country4: "",
      country5: "",
      country6:'',
      country7:'',
      country8:'',
      country9:'',
      country10:'',
      ofcno:'',
      snack_text: "",
      snack: false,
      snack_color: "",
      snack_textcolor: "",
      snack_icon: "",
      isimagefile:true
    };
  },

  methods: {
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    clearimage(){
      console.log("clear imge")
      this.previewImage1=''
      this.previewImage=''
      localStorage.removeItem("imgurl")
      // this.$refs.fileInput=''
      this.$refs.fileInput.value=null;
       console.log(this.$refs.fileInput.files[0])

    },
    validatefname(name){
       if (name == "" || name == undefined || name == null) {
        return ["Firstname is Required"];
      }
    },
     validatelname(name){
       if (name == "" || name == undefined || name == null) {
        return ["Lastname is Required"];
      }
    },
     validatephoneno1(no){
       if (no == "" || no == undefined || no == null) {
        return ["Phoneno is Required"];
      }
    },
     validateemail(mail){
       if (mail == "" || mail == undefined || mail == null) {
        return ["Emailid is Required"];
      }else{
        mail = mail.toLowerCase();
        var reg = /^([a-z0-9_\-\.])+\@([a-z0-9_\-\.])+\.([a-z]{2,4})$/;
          if (reg.test(mail) == false) {
            return ["Email not valid"];
          }
        
      }
    },
     validateaddress1(address){
       if (address == "" || address == undefined || address == null) {
        return ["Address is Required"];
      }
    },
     validateofcno(ofcno){
       if (ofcno == "" || ofcno == undefined || ofcno == null) {
        return ["Officeno is Required"];
      }
    },
     validatewebsite(site){
       if (site == "" || site == undefined || site == null) {
        return ["Website is Required"];
      }
      
      //       const regex = RegExp('(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$', 'i');
      //       return this.url.match(regex);
     
    },
    help(){
       var axios = require("axios");
       axios({
        url: MyPdf,
        method: "GET",
        responseType: "blob" // important
      }).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "help.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    manualLogout() {
      Vue.GoogleAuth.then((auth) => {
        auth.signOut().then((x) => {
          console.log(x);
          this.$store.state.isLoggedIn = false;
          this.$store.state.LoggedInUser = {};
          this.$store.state.Firstname='',
          this.$store.state.Lastname='',
          this.$store.state.Emailid='',

         
          this.snack = true;
          this.snack_color = "#26132E";
          this.snack_textcolor = "#00B300";
          this.snack_text = "Signout successfully";
          this.snack_icon = "mdi-alert-circle-outline";
           this.$router.push({
               name: "Home"})
          
        });
      });
    },
    
    getUrl(pet) {
      var path='/assets/images/'+pet;
      return path
    },
    officenocode(no) {
      this.officeno = this.code(no);
    },
    countrytele1code(c1) {
      this.phoneno1 = this.code(c1);
    },
    countrytele2code(c2) {
      this.phoneno2 = this.code(c2);
    },
    countrytele3code(c3) {
      this.phoneno3 = this.code(c3);
    },
    countrytele4code(c4) {
      this.phoneno4 = this.code(c4);
    },
    countrytele5code(c5) {
      this.phoneno5 = this.code(c5);
    },
     countrytele6code(c6) {
      this.phoneno6 = this.code(c6);
    },
     countrytele7code(c7) {
      this.phoneno7 = this.code(c7);
    },
     countrytele8code(c8) {
      this.phoneno8 = this.code(c8);
    },
     countrytele9code(c9) {
      this.phoneno9 = this.code(c9);
    },
     countrytele10code(c10) {
      this.phoneno10 = this.code(c10);
    },
    code(country) {
      if (country == "India") {
        return "91";
      }
      if (country == "Malaysia") {
        return "60";
      }
      if (country == "China") {
        return "86";
      }
      if (country == "Singapore") {
        return "65";
      }
      if (country == "USA") {
        return "1";
      }
      if (country == "Japan") {
        return "81";
      }
      if (country == "Philippines") {
        return "63";
      }
      if (country == "Pakistan") {
        return "92";
      }
      if (country == "Bangladesh") {
        return "880";
      }
      if (country == "Israel") {
        return "972";
      }
       if (country == "Iraq") {
        return "964";
      }
       if (country == "Iran") {
        return "98";
      }
       if (country == "Indonesia") {
        return "62";
      }
       if (country == "Maldives") {
        return "960";
      }
       if (country == "Arab") {
        return "971";
      }
       if (country == "Mongolia") {
        return "976";
      }
       if (country == "Saudiarabia") {
        return "966";
      }
       if (country == "Cambodia") {
        return "855";
      }
       if (country == "Kazakhstan") {
        return "7";
      }
       if (country == "Nepal") {
        return "977";
      }
       if (country == "Uzbekistan") {
        return "998";
      }
       if (country == "Brunei") {
        return "673";
      }
       if (country == "Myanmar") {
        return "95";
      }
      if (country == "Jordan") {
        return "962";
      }
      if (country == "Turkmenistan") {
        return "993";
      }
       if (country == "Bhutan") {
        return "975";
      }
       if (country == "Thailand") {
        return "66";
      }
       
       if (country == "Tajikistan") {
        return "992";
      }
      if (country == "Syria") {
        return "963";
      }
      if (country == "Sri Lanka") {
        return "94";
      }
      if (country == "Hong Kong") {
        return "852";
      }
      if (country == "Laos") {
        return "856";
      }
      if (country == "Armenia") {
        return "374";
      }
      if (country == "South Korea") {
        return "82";
      }
      if (country == "Georiga") {
        return "995";
      }
      if (country == "Oman") {
        return "968";
      }
      if (country == "Afghanistan") {
        return "93";
      }
       if (country == "Kyrgyzstan") {
        return "996";
      }
       if (country == "East Timor") {
        return "670";
      }
      if (country == "Yemen") {
        return "967";
      }
      if (country == "Kuwait") {
        return "965";
      }
      if (country == "Vietnam") {
        return "84";
      }
    },
    upload() {
     
      console.log("upload image")
      if(this.previewImage1!=''){
       
        if (this.isimagefile==true) {
          this.uploadrequest()
        }else{
          this.snack = true;
          this.snack_color = "#581030";
          this.snack_textcolor = "#F44336";
          this.snack_text = "Please upload image file";
          this.snack_icon = "mdi-alert-circle-outline";
        }
      }
      
    },
    uploadrequest(){
       var node = document.getElementById("heximg");
     
        var text = "";
          // text=this.$store.state.LoggedInUser.Ys.hU+"_"+this.$store.state.LoggedInUser.Ys.dS
         var charset = "abcdefghijklmnopqrstuvwxyz0123456789";
          for (var i = 0; i < 8; i++){
            text += charset.charAt(Math.floor(Math.random() * charset.length));
          }
        text=text+'.png'
        console.log(text)
        domtoimage.toBlob(node).then( (blob)=> {
          console.log(blob);
          var form = new FormData();
          form.append("myFile", blob, text);
          var axios = require("axios");
          var config = {
            method: "post",
            url: baseurl.url + "/icon/upload",
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: form,
          };

          axios(config)
            .then((response) => {
              console.log(response.data);
              console.log(this.previewImage)
              
              this.previewImage=text;
              localStorage.setItem('imgurl',this.previewImage)
              console.log(this.previewImage)
            })
            .catch((error) => {
              console.log(error);
              if (error.response) {
                    this.snack = true;
                    this.snack_color = "#581030";
                    this.snack_textcolor = "#F44336";
                    this.snack_text = error.response.data;
                    this.snack_icon = "mdi-alert-circle-outline";
              } else if (error.request) {
                this.snack = true;
                    this.snack_color = "#581030";
                    this.snack_textcolor = "#F44336";
                    this.snack_text = "Network Error";
                    this.snack_icon = "mdi-alert-circle-outline";
              } else {
                this.snack = true;
                this.snack_color = "#581030";
                this.snack_textcolor = "#F44336";
                this.snack_text = error.message;
                this.snack_icon = "mdi-alert-circle-outline";
              }
            });
        });
    },
    gotofinalpage() {
      if(this.$refs.EmailForm.validate()){
         localStorage.setItem('Jobdescription',this.jobdesc)
         localStorage.setItem('Department',this.department)
         localStorage.setItem('Address1',this.address1)
         localStorage.setItem('Address2',this.address2)
         localStorage.setItem('Address3',this.address3)
         localStorage.setItem('Address4',this.address4)
         localStorage.setItem('Officeno',this.officeno)
         localStorage.setItem('Phoneno1',this.phoneno1)
         localStorage.setItem('Phoneno2',this.phoneno2)
         localStorage.setItem('Phoneno3',this.phoneno3)
         localStorage.setItem('Phoneno4',this.phoneno4)
         localStorage.setItem('Phoneno5',this.phoneno5)
         localStorage.setItem('Phoneno6',this.phoneno6)
         localStorage.setItem('Phoneno7',this.phoneno7)
         localStorage.setItem('Phoneno8',this.phoneno8)
         localStorage.setItem('Phoneno9',this.phoneno9)
         localStorage.setItem('Phoneno10',this.phoneno10)
         localStorage.setItem('twitter',this.twitter)
         localStorage.setItem('linkin',this.linkin)
         localStorage.setItem('website',this.website)
         localStorage.setItem('imgurl',this.previewImage)
      this.$router.push({
        name: "FinalPage",
        params: {
          finaltemplate: this.template,
          finalfname: this.fname,
          finallname: this.lname,
          finaljobdesc: this.jobdesc,
          finalscolor: this.scolor,
          finalphoneno1: this.phoneno1,
          finalphoneno2: this.phoneno2,
          finalphoneno3: this.phoneno3,
          finalphoneno4: this.phoneno4,
          finalphoneno5: this.phoneno5,
          finalphoneno6: this.phoneno6,
          finalphoneno7: this.phoneno7,
          finalphoneno8: this.phoneno8,
          finalphoneno9: this.phoneno9,
          finalphoneno10: this.phoneno10,
          finalemail: this.email,
          finalwebsite: this.website,
          finaladdress1: this.address1,
          finaladdress2: this.address2,
          finaladdress3: this.address3,
          finaladdress4: this.address4,
          finalpreviewImage: this.previewImage,
          finalvar1: this.var1,
          finalvar2: this.var2,
          finalvar3: this.var3,
          finalvar4: this.var4,
          finallinkin: this.linkin,
          finaltwitter: this.twitter,
          finaldepartment:this.department,
          finalofficeno:this.officeno
        },
      });
      }else{
        this.snack = true;
              this.snack_color = "#581030";
              this.snack_textcolor = "#F44336";
              this.snack_text = "Fill all required field";
              this.snack_icon = "mdi-alert-circle-outline";
      }
    },
    restart() {
       
        (this.template = "t1"),
        (this.fname = this.$store.state.Firstname),
        (this.lname = this.$store.state.Lastname),
        (this.jobdesc = "Developer"),
        (this.scolor = "#DD2977"),
        (this.phoneno1 = "1234567890"),
        (this.phoneno2 = ""),
        (this.phoneno3 = ""),
        (this.phoneno4 = ""),
        (this.phoneno5 = ""),
        (this.phoneno6 = ""),
        (this.phoneno7 = ""),
        (this.phoneno8 = ""),
        (this.phoneno9 = ""),
        (this.phoneno10 = ""),
        (this.previewImage1 =''),
        (this.email = this.$store.state.Emailid),
        (this.website = "www.crayonte.com"),
        (this.address1 = "35-10"),
        (this.address2 = "The Boulevard"),
        (this.address3 = "Midvalley City 59200"),
        (this.address4 = "Kuala lumpur"),
        (this.previewImage =''),
        (this.var1 = "ubuntu"),
        (this.var2 = "normal"),
        (this.var3 = "10px"),
        (this.var4 = "#383838");
        this.department='Department',
        this.officeno='1234567890'
        this.$refs.fileInput.value=null;
        this.addno=0

    },
    pickFile() {
      console.log("image selected")
      console.log(this.$refs.fileInput)
      console.log(this.$refs.fileInput.files[0])
      console.log(this.previewImage1)
      let input = this.$refs.fileInput;
      let file = input.files;
     
      var checkimg = new RegExp("([a-zA-Z0-9()s_\\.-:])+(.jpg|.png|.svg|.jpeg)$");
     
      
          if (file && file[0]) {
            if (checkimg.test(file[0].name.toLowerCase())) {
              this.isimagefile=true
              let reader = new FileReader();
              reader.onload = (e) => {
                this.previewImage1 = e.target.result;
                
                console.log(e);
                console.log(this.previewImage);
              };

              reader.readAsDataURL(file[0]);
              this.$emit("input", file[0]);
            } 
            else{
              console.log("at else")
              this.snack = true;
              this.snack_color = "#581030";
              this.snack_textcolor = "#F44336";
              this.snack_text = "Please upload image file";
              this.snack_icon = "mdi-alert-circle-outline";
              this.isimagefile=false
            }
            
          }
     
    },
  },
  // mounted: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = 'hidden'
  // },
  // destroyed: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = null
  // }
};
</script>

<style>
.hex {
  display: flex;
  position: relative;
  width: 100.79px;
  height: 114.38px;
  border-radius: 40px;
  background-color: #424242;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.card7 {
  width: 513px !important;
  height: 372px;
}

.t1 {
  width: 580px !important;
  height: 202px !important;
}

.theme--light.v-select .v-select__selections {
  color: #d6d7e9 !important;
  min-height: 10px !important;
}
.v-select__slot {
  position: relative;
  align-items: baseline !important;
  display: flex;
  color:rgba(255, 255, 255, 0.6)!important;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  height: 26px !important;
}
.mdi-menu-down::before {
  content: "\F035D";
  color: rgba(255, 255, 255, 0.6)!important;
  min-height: 22px;
  max-height: 27px;
}

.v-application p {
  margin-bottom: 0px !important;
}
.imagePreviewWrapper {
  width: 110px !important;
  height: 110px !important;

  cursor: pointer;

  border: 1px dashed #d6d7e9;
  box-sizing: border-box;
}

.v-application .primary--text {
  color: #d6d7e9 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #d6d7e9 !important;
}
.theme--light.v-label {
  color: #d6d7e9 !important;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: white !important;
}
.v-btn {
  text-transform: none !important;
}
.v-tab {
  text-transform: none !important;
}
.builder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}
.signature {
  object-fit: cover;
  overflow: hidden !important;
  scrollbar-width: none;
}
.cardhead {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  color: #d6d7e9;
}
.uploadprofile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #d6d7e9;
}
.signaturepreview {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fdb614;
}
.theme--light.v-list {
    background: #191A45!important;
    color: rgba(255, 255, 255, 0.6)!important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 255, 255, 0.6)!important;
}
.theme--light.v-list, .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 255, 255, 0.6)!important;
}
.v-list-item {
    align-items: center;
    display: flex;
    color:rgba(255, 255, 255, 0.6)!important;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 48px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
}
.v-tab--active {
    background: #10102B!important;
    border-top-right-radius: 20px!important;
    border-top-left-radius: 20px!important;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip) {
    border-bottom-left-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}
.theme--light.v-tabs-items {
  background: #10102B!important;
      /* border-top-right-radius: 20px!important;
    border-top-left-radius: 20px!important; */
}
::-webkit-scrollbar {
  width: 4px;
 
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #10102B; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #D6D7E9; 
  border-radius: 2px;
  height: 50px!important;
 cursor: pointer!important;
}
/* ::-webkit-scrollbar-button {
  height: 150px!important;
  cursor: pointer!important;
} */
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D6D7E9; 
  cursor: pointer;
}
input[type="file"] {
    display: none;
}
label.forfile{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #FDB614;
    margin-top:10px;

     }
     .forfile{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #FDB614;
    margin-top:10px;

     }
</style>